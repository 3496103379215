import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

import ArchivedNews from '../components/archived/archived-news'
import ArchivedSummaries from '../components/archived/archived-summaries'
import ArchivedPreviews from '../components/archived/archived-previews'
import ArchivedEventTitles from '../components/archived/archived-event-titles'

import { isBrowser } from '../components/helpers.js'

import '../scss/page.scss'
import '../components/archived/archived.scss'

/**
 * TODO:
 * 1. Create a functional component for semester. Semester has year and semester name.
 * Functional component can be called inside a map loop.
 *
 * Use comprehension and children
 */
class ArchivePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      blogPostType: isBrowser() ? window.localStorage.getItem('blogPostType') ? window.localStorage.getItem('blogPostType') : 'summary' : 'summary',
      initialSummaryData: this.props.data.SummaryQuery,
      initialPreviewData: this.props.data.PreviewQuery,
      initialNewsData: this.props.data.NewsQuery,
    }

    this.changeBlogPostType = this.changeBlogPostType.bind(this)
  }

  changeBlogPostType(type) {
    window.localStorage.setItem('blogPostType', type)
    this.setState({blogPostType: type})
  }

  render() {
    const ArchiveTypeTranslations = {
      all: 'Kaikki', news: 'Uutiset', preview: 'Ennakot', summary: 'Koosteet', eventTitles: 'Aiheet',
    }

    return (<Layout>
      <Seo
        description={'Arkistosta löydät ennakot ja uutiset sekä koosteet aikaisemmista kokoontumisista.'}
        title={'Arkisto'}
      />
      <div className={'page-block'}>
        <h2 className="uk-heading-small">
          {ArchiveTypeTranslations[this.state.blogPostType]}
        </h2>
        <p className="uk-margin-medium-top">
          Valitse arkistoidun artikkelin tyyppi:
        </p>

        <div>
          <ul className="uk-subnav uk-subnav-pill" uk-switcher="">
            <li
              className={this.state.blogPostType === 'all' ? 'uk-active' : ''}
            >
              <a
                href="#"
                onClick={() => this.changeBlogPostType('all')}
                onKeyDown={() => this.changeBlogPostType('all')}
              >
                Kaikki
              </a>
            </li>
            <li
              className={this.state.blogPostType === 'summary' ? 'uk-active' : ''}
            >
              <a
                href="#"
                onClick={() => this.changeBlogPostType('summary')}
                onKeyDown={() => this.changeBlogPostType('all')}
              >
                Koosteet
              </a>
            </li>
            <li
              className={this.state.blogPostType === 'news' ? 'uk-active' : ''}
            >
              <a
                href="#"
                onClick={() => this.changeBlogPostType('news')}
                onKeyDown={() => this.changeBlogPostType('all')}
              >
                Uutiset
              </a>
            </li>
            <li
              className={this.state.blogPostType === 'preview' ? 'uk-active' : ''}
            >
              <a
                href="#"
                onClick={() => this.changeBlogPostType('preview')}
                onKeyDown={() => this.changeBlogPostType('all')}
              >
                Ennakot
              </a>
            </li>
            <li
              className={this.state.blogPostType === 'eventTitles' ? 'uk-active' : ''}
            >
              <a
                href="#"
                onClick={() => this.changeBlogPostType('eventTitles')}
                onKeyDown={() => this.changeBlogPostType('all')}
              >
                Aiheet
              </a>
            </li>
          </ul>

          <div className="uk-switcher">
            <div>
              <ArchivedNews
                data={this.state.initialNewsData}
                showCaption={true}
              />
              <ArchivedSummaries
                data={this.state.initialSummaryData}
                showCaption={true}
              />
              <ArchivedPreviews
                data={this.state.initialPreviewData}
                showCaption={true}
              />
            </div>
            <div>
              <ArchivedSummaries data={this.state.initialSummaryData}/>
            </div>
            <div>
              <ArchivedNews data={this.state.initialNewsData}/>
            </div>
            <div>
              <ArchivedPreviews data={this.state.initialPreviewData}/>
            </div>
            <div>
              <ArchivedEventTitles/>
            </div>
          </div>
        </div>
      </div>
    </Layout>)
  }
}

export default ArchivePage

// Reuse this in all of the queries that fetch summaries
export const summaryFragment = graphql`
  fragment summaryFrontmatterFields on MarkdownRemark {
    frontmatter {
      id
      title
      path
      date(locale: "fi_FI")
      publishOn
      type
      tags
      categories
      speaker
      location
      year: date(formatString: "YYYY")
      month: date(formatString: "M")
    }
  }
`

export const data = graphql`
  query {
    SummaryQuery: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "summary" }, tags: { ne: "test" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          ...summaryFrontmatterFields
        }
      }
    }

    PreviewQuery: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "preview" }, tags: { ne: "test" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          ...summaryFrontmatterFields
        }
      }
    }

    NewsQuery: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "news" }, tags: { ne: "test" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          ...summaryFrontmatterFields
        }
      }
    }
  }
`
