import React from 'react'
import { Link } from 'gatsby'

/** Outputs a neat 'Read more' link button on tables */
export default function ReadMoreTableLink(props) {
  return (
    <Link
      to={props.url}
      className={'uk-button uk-button-primary uk-button-small'}
    >
      {props.linkText} <span uk-icon={props.iconClass}></span>
    </Link>
  )
}
