import React from 'react'
import ReadMoreTableLink from '../ReadMoreTableLink'

const moment = require('moment')
moment.locale('fi_FI')

function ArchivedPreviews(props) {
  return (
    <table className="uk-table uk-table-divider uk-margin-medium-top uk-table-hover uk-table-responsive">
      {props.showCaption ? (
        <caption>
          <span className="uk-text-large uk-text-bold uk-text-emphasis">
            Ennakot
          </span>
        </caption>
      ) : null}
      <thead>
        <tr>
          <th>Julkaistu</th>
          <th>Kokoontumisajankohta</th>
          <th>Alustaja(t)</th>
          <th>Aihe</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {props.data.edges.map((row, key) => {
          return (
            <tr key={key} className="singlePreview">
              <td className="publishOn">
                {row.node.frontmatter.publishOn
                  ? moment(row.node.frontmatter.publishOn).format(
                      'DD.MM.YYYY [klo] HH:mm'
                    )
                  : ''}
              </td>
              <td className="date">
                {moment(row.node.frontmatter.date).format(
                  'DD.MM.YYYY [klo] HH:mm'
                )}
              </td>
              <td className="speaker">{row.node.frontmatter.speaker}</td>
              <td className="title">{row.node.frontmatter.title}</td>
              <td className="readArticle uk-table-expand">
                <ReadMoreTableLink
                  url={row.node.fields.slug}
                  linkText={'Lue ennakko'}
                  iconClass={'arrow-right'}
                />
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default ArchivedPreviews
