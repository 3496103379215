import React from 'react'
import dayjs from 'dayjs'

import scheduleAutumn2017 from '../../schedules/autumn-2017.json'
import scheduleSpring2018 from '../../schedules/spring-2018.json'
import scheduleAutumn2018 from '../../schedules/autumn-2018.json'
import scheduleSpring2019 from '../../schedules/spring-2019.json'
import scheduleAutumn2019 from '../../schedules/autumn-2019.json'
import scheduleSpring2020 from '../../schedules/spring-2020.json'
import scheduleAutumn2020 from '../../schedules/autumn-2020.json'
import scheduleSpring2021 from '../../schedules/spring-2021.json'
import scheduleAutumn2021 from '../../schedules/autumn-2021.json'
import scheduleSpring2022 from '../../schedules/spring-2022.json'
import scheduleAutumn2022 from '../../schedules/autumn-2022.json'

import './archived.scss'

function ArchivedEventTitles(props) {
  const fullSchedule = [
    ...scheduleAutumn2017,
    ...scheduleSpring2018,
    ...scheduleAutumn2018,
    ...scheduleSpring2019,
    ...scheduleAutumn2019,
    ...scheduleSpring2020,
    ...scheduleAutumn2020,
    ...scheduleSpring2021,
    ...scheduleAutumn2021,
    ...scheduleSpring2022,
    ...scheduleAutumn2022]

  fullSchedule.reverse()

  return (<div>
    <div className={'uk-alert uk-alert-primary'}><p>Listauksesta löydät kaikki aiheet aikaväliltä 11.9.2017 - 31.12.2022.</p>
      <p>Kevätkausi 2021 jäi kokonaan pitämättä koronaviruksen vuoksi.</p></div>

    <table className="uk-table uk-table-divider uk-table-justify uk-table-responsive">
      <thead>
      <tr>
        <th>#</th>
        <th>Ajankohta</th>
        <th>Alustaja(t)</th>
        <th>Aihe</th>
      </tr>
      </thead>
      <tbody>
      {fullSchedule.map((singleRow, iterator) => {
        return (<tr key={singleRow.datetime}>
          <td className={'numeral'}>{fullSchedule.length - iterator}.</td>
          <td className={'numeral'}>{dayjs(singleRow.datetime).format('D.M.YYYY')}</td>
          <td>{singleRow.speaker}</td>
          <td>{singleRow.title}</td>
        </tr>)
      })}
      </tbody>
    </table>
  </div>)
}

export default ArchivedEventTitles
